import { Controller } from '@hotwired/stimulus';

// A simple popover controller that 'copies' the template on top in the body
// so it will always hover over the rest of your page.
//
// Needs a data-popover-target="template" attribute to target the content that needs to be shown.
// For the rest a show/hide to toggle the popover. this can be done with for ex. click or mouseover/mouseout.
export default class extends Controller {
  static targets = ['template'];
  EXTRA_MARGIN = 40;

  connect () {
    this.template = this.templateTarget;
  }

  show (evt) {
    const target = evt.target;

    const targetRect = target.getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    const windowWidth = window.innerWidth;

    if (!this.popup) {
      this.popup = this.template.cloneNode(true);
    }

    // To get the absolute x and y pos of the element the tooltip should position to.
    const posX = targetRect.x - bodyRect.x;
    // Add the height (offsetHeight) so the tooltip hang under. Normally it positions at top left.
    const posY = targetRect.y - bodyRect.y + target.offsetHeight;
    const popupStyle = `transform: translate(${posX}px, ${posY}px);`;

    document.body.insertAdjacentElement('afterbegin', this.popup);
    this.popup.classList.remove('hidden');

    const popupRect = this.popup.getBoundingClientRect();
    // To prevent part of the popup going out the right side of the screen when the popup is close to the edge.
    // This we add a max-with witht the remaining screen space minus a little bit.
    const extraPopupWidth = (posX + popupRect.width) - (windowWidth - this.EXTRA_MARGIN);

    this.popup.style = `${popupStyle} max-width: ${popupRect.width - extraPopupWidth}px;`;
  }

  hide () {
    this.popup.remove();
  }
}
