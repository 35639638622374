import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggleable', 'driver'];
  static values = {
    open: { type: Boolean, default: false },
    selectedToggle: { type: String, default: '' }
  };

  connect () {
    this.toggleClass = this.data.get('class') || 'hidden';
    if (this.hasDriverTarget) {
      this.toggle(this.driverTarget);
    }
  }

  toggle (eventOrNode) {
    const targetNode = eventOrNode instanceof Event ? eventOrNode.target : eventOrNode;

    switch (targetNode.type) {
      case 'checkbox':
        this.openValue = targetNode.checked;
        break;
      case 'select-one':
        this.selectedToggleValue = targetNode.value;
        break;
      default:
        if (eventOrNode.preventDefault) {
          eventOrNode.preventDefault();
        }
        this.openValue = !this.openValue;
    }
  }

  hide (event) {
    event.preventDefault();

    this.openValue = false;
  }

  show (event) {
    event.preventDefault();

    this.openValue = true;
  }

  openValueChanged () {
    if (!this.toggleClass) return;

    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.toggleClass, !this.openValue);
    });
  }

  selectedToggleValueChanged () {
    this.toggleableTargets.forEach(target => {
      target.classList.toggle(this.toggleClass, target.dataset.selectedToggleValue !== this.selectedToggleValue);
    });
  }
}
